<template>
    <v-dialog width="590px" scrollable persistent  v-model="isShowDetails">
        <!-- <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" /> -->
        <v-card ref="formCard" height="700px" width="590px">
            <partial-remittance-form ref="partialRemittanceForm" :is-show="isShowPartialTransactionForm" :form-type="partialDetailsFormType" :selected-item="selectedTransaction" v-on:close-dialog="closeDialog" v-on:update-partial-transaction="updatePartialTransaction"/>
            <v-card-title style = "height: 50px;" class="headline">
                {{details.item.qrRetrievalReferenceNo}}
            </v-card-title>
            <v-card-text>
                <v-row v-for="(field, index) of getFields()" :key="index">
                    <v-col cols="12" v-if="isJSON(field[1])">
                        <v-textarea auto-grow rows="1" :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-text-field :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions height="700px" width="590px">
            <v-spacer></v-spacer>
                <v-flex xs12>
                    <v-btn color="green darken-1" text @click="close" data-cy="form-close-button">
                        Close
                    </v-btn>
                </v-flex>
                <v-flex>
                    <v-btn color="green darken-1" text @click="forRequest" v-if="isValidRequestedStatus()" data-cy="form-declined-button">
                        Mark as REQUESTED 
                    </v-btn>
                </v-flex>
                <v-flex>
                    <v-btn color="green darken-1" text @click="forWithdrawalPending"  v-if="isValidPendingStatus()" data-cy="form-for-validation-button">
                        Mark as PENDING
                    </v-btn>
                </v-flex>
                <v-flex>
                    <v-btn color="green darken-1" text @click="markCompleted()" v-if="isValidCompletedStatus()" data-cy="form-pending-button">
                        Mark as COMPLETED
                    </v-btn>
                </v-flex>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Loading from '@/views/components/Loading.vue'
import PartialRemittanceForm from '@/views/components/PartialRemittanceForm.vue'

export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        details: Object
    },
    components: {
        Loading,
        PartialRemittanceForm
    },
    watch: {
        isShow: {
            handler(value) {
                this.isShowDetails = value
            }
        },
        deep: true,
    },
    details: {
        handler(value) {
            this.itemDetails = value
        },
        deep: true,
    },
    data() {
        return {
            isShowDetails: this.isShow ?? false,
            itemDetails: this.details,
            loading: true,
            loadingMessage: "",
            loadingFinished: false,
            page: 1,
            totalReports: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            displayReports: [],
            remainderReports: [],
            isHtml: false,
            partialDetailsFormType: 'CREATE',
            selectedTransaction: {},
            isShowPartialTransactionForm: false,
        }
    },
    methods: {
        getFields() {
            const arrOfArrays = Object.entries(this.itemDetails.item);

            const sorted = arrOfArrays.sort((a, b) => {
            return a[0].localeCompare(b[0]);
            });

            return sorted;
        },
        convertCamelCase(string) {
            return string.replace(/([a-z])([A-Z])/g, '$1 $2');
        },
        close() {
            this.isShowDetails = false
            this.$emit('close-dialog', 'details')
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.isShowPartialTransactionForm = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                // this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        isJSON(value) {
          return Object.prototype.toString.call(value) === '[object Array]' || Object.prototype.toString.call(value) === '[object Object]'
        },
        stringify(value) {
          if(Object.prototype.toString.call(value) === '[object Array]') {
            var items = []
            for(var index in value) {
              if(value[index].role) {
                items.push(value[index].role)
              }
            }
            if(items.length > 0) {
              return items.toString()
            }
            return JSON.stringify(value)
          } else if(Object.prototype.toString.call(value) === '[object Object]') {
            if(value.role) {
              return value.role
            }
            return JSON.stringify(value)
          } else {
            return value.toString()
          }
        },
        forAuthPending() {
            this.details.status = "AUTHORIZATION_PENDING"
            this.details.id = this.details.id
            this.details.amount = this.details.amount
            this.details.remarks = this.details.remarks
            this.$emit("update-status", this.details);
            this.$refs.formCard.$el.scrollIntoView({
            behavior: "smooth",
            });
        },
        forWithdrawalPending() {
            this.details.status = "WITHDRAWAL_PENDING"
            this.details.id = this.details.id
            this.details.amount = this.details.amount
            this.details.remarks = this.details.remarks
            this.$emit("update-status", this.details);
            this.$refs.formCard.$el.scrollIntoView({
            behavior: "smooth",
            });
        },
        forRequest() {
            this.details.status = "WITHDRAWAL_REQUESTED"
            this.details.id = this.details.id
            this.details.amount = this.details.amount
            this.details.remarks = this.details.remarks
            this.$emit("update-status", this.details);
            this.$refs.formCard.$el.scrollIntoView({
            behavior: "smooth",
            });
        },
        markCompleted() {
            if(this.details.status === "WITHDRAWAL_INCOMPLETE") {
                this.updatePartialTransaction("UPDATE", this.details.item)
            } else {
                this.details.status = "WITHDRAWAL_COMPLETED"
                this.details.id = this.details.item.id
                this.details.amount = this.details.item.amount ?? ''
                this.details.remarks = this.details.item.remarks
                this.details.qrRetrievalReferenceNo = this.details.item.qrRetrievalReferenceNo
                this.$emit("update-status", this.details);
                this.$refs.formCard.$el.scrollIntoView({
                behavior: "smooth",
                });
            }
        },
        isValidPendingStatus() {
            if(this.details.status === "WITHDRAWAL_REQUESTED" || this.details.status === "WITHDRAWAL_INCOMPLETE" ) {
                return true 
            } else {
                return false
            }
        },
        isValidRequestedStatus() {
            if(this.details.status === "WITHDRAWAL_PENDING" || this.details.status === "WITHDRAWAL_COMPLETED" ) {
                return false 
            } else {
                return false
            }
        },
        isValidCompletedStatus() {
            if(this.details.status === "WITHDRAWAL_REQUESTED" || this.details.status === "WITHDRAWAL_INCOMPLETE") {
                return true 
            } else {
                return false
            }
        },
        updatePartialTransaction(what, item = {}) {
            console.log(what)
            console.log(item)
            this.partialDetailsFormType = what
            this.selectedTransaction = item
            this.isShowPartialTransactionForm = true
        }
    }
}
</script>
