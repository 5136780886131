<template>
  <div>
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage"
             v-on:close-dialog="closeDialog"/>
    <remittance-transaction-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog"
                  v-on:update-status="updateStatus"/>
    <v-container class="container--fluid grid-list-md text-center">
      <h1>Cardless Withdrawal</h1>
    </v-container>

    <v-card elevation="3" outlined shaped tile padding="30px">
      <v-row class="ma-1">
        <v-col cols="4">
          <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y max-width="265px" min-width="265px" data-cy="date-menu-from">
            <template v-slot:activator="{ on }">
              <v-text-field label="Date Created" prepend-icon="mdi-calendar" readonly :value="fromDateDisp"
                            v-on="on" data-cy="date-menu-from"></v-text-field>
            </template>
            <v-date-picker locale="en-in" v-model="fromDateVal" no-title @input="fromDateMenu = false" :min="minDate"
                           :max="fromMax" data-cy="date-menu-from"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y max-width="265px" min-width="265px" data-cy="date-menu-to">
            <template v-slot:activator="{ on }">
              <v-text-field label="Date Completed" prepend-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on"
                            data-cy="date-menu-to"></v-text-field>
            </template>
            <v-date-picker locale="en-in" v-model="toDateVal" no-title @input="toDateMenu = false" :max="maxDate"
                           :min="toMin" data-cy="date-menu-to">
              <v-btn text color="primary" @click="clearToDate()" data-cy="button-clear">Clear
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex" cols="3">
          <v-text-field class="px-4" label="Retrieval Reference #" single-line @change="searchRetrievalReference"
                        data-cy="module-search-retrieval-reference"></v-text-field>
        </v-col>
      </v-row>

      <v-row class="ma-4">
        <v-col class="d-flex ml-1" cols="4">
          <v-text-field class="px-4" label="Cashout Partner" single-line @change="searchCashOutPartner"
                        data-cy="module-search-cashout-partner"></v-text-field>
        </v-col>
        <v-col class="d-flex ml-2 mb-2" cols="4">
          <v-select class="px-4" :items="cashOutStatus" label="Status" @change="searchStatus"
                    data-cy="module-search-status"></v-select>
        </v-col>
      </v-row>
      <v-card-actions elevation="2" class="container--fluid grid-list-md text-right">
        <v-row>
            <v-col cols="9">

            </v-col>
            <v-col cols="2">
                <v-btn dark medium color=success @click="queryData()" data-cy="button-search">
                    <v-icon dark left> mdi-magnify</v-icon>
                    Search
                </v-btn>
            </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-data-table :headers="headers" :options.sync="options"
                  :loading="loading"
                  :items="displayReports"
                  :page="page"
                  :pageCount="numberOfPages"
                  :server-items-length="totalReports"
                  :footer-props="{'items-per-page-options': [10, 20, 50]}">
      <template v-slot:body="{ items }">
        <tbody>
        <tr :colspan="headers.length" v-for="item in items" :key="item.id">
          <td><span class="d-flex"> {{ item.qrRetrievalReferenceNo }} </span></td>
          <td><span class="d-flex justify-end"> <pre>{{ item.qrMercName }} </pre></span></td>
          <td><span class="d-flex"> {{ item.qrTrxnAmount }} </span></td>
          <td><span class="d-flex justify-center"> {{ item.remainingAmount }} </span></td>
          <td><span class="d-flex"> {{ item.status }} </span></td>
          <td><span class="d-flex"> {{ item.dateCreated }} </span></td>
          <td>
            <span class="d-flex justify-center">
                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewTransaction(item)">
                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                </v-btn>
            </span>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import CardlessAPI from '@/api/CardlessAPI'
import Loading from '@/views/components/Loading.vue'
import RemittanceTransactionForm from '@/views/components/RemittanceTransactionForm.vue'
import moment from 'moment';

export default {
    name: 'Transactions',
    components: {
        Loading,
        RemittanceTransactionForm,
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities'
        ]),
        fromDateDisp() {
            this.toMin = moment().format(this.fromDateVal)
            return this.fromDateVal;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.toDateVal)
            return this.toDateVal;
        },
    },
    data() {
        return {
            disableCsvExportButton: true,
            cashOutStatus: ['', 'AUTHORIZATION_PENDING', 'WITHDRAWAL_PENDING', 'WITHDRAWAL_REQUESTED', 'WITHDRAWAL_COMPLETED','WITHDRAWAL_INCOMPLETE'],
            headers: [
                {
                    text: 'Retrieval Reference No.',
                    value: 'qrRetrievalReferenceNo',
                    sortable: false,
                },
                {
                    text: 'CashOut Partner',
                    value: 'qrMercName',
                    sortable: false,
                    align: 'right',
                },
                {
                    text: 'Amount',
                    value: 'qrTrxnAmount',
                    sortable: false,
                },
                {
                    text: 'Credited to CLiQQ wallet',
                    value: 'remainingAmount',
                    sortable: false,
                },
                {
                    text: 'Status',
                    value: 'status',
                    sortable: false,
                },
                {
                    text: 'Date Creation',
                    value: 'dateCreated',
                    sortable: false,
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false,
                },
            ],
            isNotMerchant: false,
            records: [],
            merchants: [],
            merchantList: this.merchants ?? [],
            loading: true,
            selectedMerchant: {},
            selectedStatus: '',
            selectedQrRetrievalReferenceNo: '',
            selectedCashOutPartner: '',
            selectedId:'',
            page: 1,
            totalReports: 0,
            numberOfPages: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
            },
            details: {
                title: '',
                item: {}
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            offset: 0,
            limit: 0,
            displayReports: [{}],
            remainderReports: [],
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isShowDetails: false,
            isHtml: false,

        }
    },
    async mounted() {
        this.loadingDialog = true;
        this.setDefaultDateRange();
        this.loadingDialog = false;
    },
    watch: {
        options: {
            handler() {
                this.queryData();
            },
            deep: true,
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
    },
    methods: {
        hasPermission(authority) {
            if (this.authorities.some(x => x.name === authority)) {
                return true
            }
            return false
        },
        // deprecate
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var transactionList = undefined

                const parameters = {
                    offset: pageNumber,
                    limit: itemsPerPage,
                    id: this.selectedId,
                    qrRetrievalReferenceNo: this.selectedQrRetrievalReferenceNo,
                    qrMercName: this.qrMercName,
                    status: this.selectedStatus,
                    dateFrom: this.fromDateVal,
                    dateTo:this.toDateVal
                }
                transactionList = await CardlessAPI.filterCashoutTransaction(parameters)
                if (!transactionList || transactionList.error) {
                    //error getting data
                    console.log(`${transactionList.error}`)
                } else {
                    var filteredList = transactionList.transaction
                    this.isNotMerchant = transactionList.isNotMerchant
                    if (page > 1 && this.remainderReports.length > 0) {
                        filteredList = this.remainderReports.concat(filteredList)
                    }
                    this.totalReports = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayReports = filteredList.slice(0, end)
                    this.remainderReports = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalReports / itemsPerPage
                }
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
        //method to update the Merchant query
        searchStatus: async function (val) {
            this.selectedStatus = val
            // this.queryData(true)
        },
        searchRetrievalReference(val) {
            this.selectedQrRetrievalReferenceNo = val
            // this.queryData(true)
        },
        searchCashOutPartner(val) {
            this.selectedCashOutPartner = val
            // this.queryData(true)
        },
        setDefaultDateRange() {
            this.fromDateVal = moment().subtract(1, "day").format("yyyy-MM-DD");
            this.toDateVal = moment().format("yyyy-MM-DD");
            this.maxDate = moment().add(1, "days").format("yyyy-MM-DD")
            this.fromMax = moment().add(1, "days").format("yyyy-MM-DD")
            this.toMin = moment().format(this.fromDateVal)
        },
        clearToDate() {
            this.toDateVal = ''
            this.toDateMenu = false
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.isShowPartialTransactionForm = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                // this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        viewTransaction(transaction) {
            console.log(transaction)
            this.details.title = transaction.qrRetrievalReferenceNo
            this.details.id = transaction.id
            this.details.status = transaction.status
            this.details.item = transaction
            this.isShowDetails = true
        },
        async updateStatus(value) {
            console.log("value.payconnectReference : " + value.qrRetrievalReferenceNo)
            console.log("value.status: " + value.status)
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                this.loadingMessage = `Updating transaction <strong><i>${value.qrRetrievalReferenceNo}</i></strong>`
                setTimeout(async () => {
                    const createResponse = await CardlessAPI.updateCashoutStatus(value)
                    console.log("RESPONSE: " + createResponse)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.isHtml = false
                        this.loadingMessage = createResponse.error.response.data.message
                    } else {
                        this.loadingMessage = `Successfully updated transaction <strong><i>${value.qrRetrievalReferenceNo}</i></strong>`
                        this.showFormDialog = false
                        // this.$refs.userForm.resetForm()
                    }
                }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
    }
};
</script>
