import Vue from 'vue'
import router from '@/router/index';

export default {

    async updateCashoutStatus(parameters) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_CASHOUT_ADMIN_URL}/cashouts/updateStatus`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = '/switch/transaction/updateStatus';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            id: parameters.id,
            amount: parameters.amount,
            status: parameters.status,
            remarks: parameters.remarks
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },
        
    async filterCashoutTransaction(parameters) {

        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_CASHOUT_ADMIN_URL}/cashouts/search`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/transaction/search';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: parameters.offset,
            pageSize: parameters.limit,
            id: parameters.id,
            qrRetrievalReferenceNo: parameters.qrRetrievalReferenceNo,
            qrMercName: parameters.qrMercName,
            status: parameters.status,
            dateFrom: parameters.dateFrom,
            dateTo: parameters.dateTo,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },
}
