<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
         <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage"
             v-on:close-dialog="closeDialog"/>
        <v-card ref="formCard">
            <v-card-title>
                PARTIAL RETRACTED TRANSACTION FORM:{{selectedItem.qrRetrievalReferenceNo}}
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="ID" readonly v-model="form.id" required :rules="[rules.requiredField]" data-cy="form-id"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                label="Original Amount"
                                hide-details="auto"
                                data-cy="form-amount-retracted"
                                v-model="form.amount"
                                readonly
                                :rules="[rules.requiredField]"
                            ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                label="Amount Withdrawn*"
                                clearable
                                hide-details="auto"
                                data-cy="form-amount-withdrawn"
                                v-model="form.qrTrxnAmount"
                                @keypress="isNumber($event)"
                            ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Remarks*" v-model="form.remarks" data-cy="form-remarks"  :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-flex xs12>
                    <v-btn color="green darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                </v-flex>
                <v-flex>
                    <v-btn color="green darken-1" text v-on:click="updateStatus()" data-cy="update-button">Update</v-btn>
                </v-flex>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
import Loading from '@/views/components/Loading.vue'
import CardlessAPI from '@/api/CardlessAPI'

export default {
    props: {
        isShow: Boolean,
        formType: String,
        selectedItem: Object,
        merchants: Array
    },
    components: {
        Loading
    },
    computed: {
        populatedForm() {
            this.selectedItem.id = this.form.id,
            this.selectedItem.qrTrxnAmount = this.form.qrTrxnAmount,
            this.selectedItem.amount = this.form.qrTrxnAmount,
            this.selectedItem.remarks = this.form.remarks
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            loadingMessage: "",
            loadingFinished: false,
            loadingDialog: false,
            isHtml: false,
            form: {
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
                properEmail: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid. Ex. juandelacruz@apollo.com.ph'
            }
        }
    },
    async mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        async updateStatus() {
            //update status before calling API for partial remittance
            console.log("value.payconnectReference : " + this.selectedItem.qrRetrievalReferenceNo)
            console.log("value.status: " + this.selectedItem.status)
            console.log("value of populatedForm: " + this.populatedForm)
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            this.selectedItem.status = "WITHDRAWAL_COMPLETED"
            if (this.selectedItem) {
                this.loadingMessage = `Updating transaction <strong><i>${this.selectedItem.qrRetrievalReferenceNo}</i></strong>`
                console.log("value.status: " + this.selectedItem.status)
                setTimeout(async () => {
                    const createResponse = await CardlessAPI.updateCashoutStatus(this.selectedItem)
                    console.log(createResponse)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.isHtml = false
                        this.loadingMessage = createResponse.error
                    } else {
                        this.loadingMessage = `Successfully updated transaction <strong><i>${this.selectedItem.qrRetrievalReferenceNo}</i></strong>`
                        this.showFormDialog = false
                    }
                }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.isShowPartialTransactionForm = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                // this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            this.form.id = this.selectedItem.id,
            this.form.qrTrxnAmount = this.selectedItem.qrTrxnAmount,
            this.form.amount = this.selectedItem.amount,
            this.form.remarks = this.selectedItem.remarks
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    }
}
</script>
